<template>
    <div class="container">
        <BackHead></BackHead>
        <div class="main">
            <el-menu active-text-color="#407BDB" class="el-menu-vertical-demo" :index="$router.path" router>
                <el-menu-item index="showManagement">
                    <i class="el-icon-s-order"></i>
                    <template #title>展示管理</template>
                </el-menu-item>
                <el-menu-item index="replyManagement">
                    <i class="el-icon-chat-line-square"></i>
                    <template #title>回复管理</template>
                </el-menu-item>
            </el-menu>
            <div class="content">
                <transition name="nodebook" mode="out-in">
                    <router-view />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import BackHead from '../components/BackHead'
export default {
    components:{ BackHead },
    created(){
        document.body.style.height = "100%"
        document.documentElement.style.height = "100%"
    }
}
</script>

<style lang="scss" scoped>
.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.main{
    flex: 1;
    display: flex;
}
.el-menu{
    border: none;
    background-color: #FFFFFF;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 100%;
}
.el-submenu .el-menu-item{
    min-width: 0 !important;
}
.nodebook-enter, .nodebook-leave-to{
    transform: scale(0.9);
    opacity: 0;
}
.nodebook-enter-to, .nodebook-leave{
    transform: scale(1);
    opacity: 1;
}
.nodebook-enter-active, .nodebook-leave-active {
    transition: all 0.3s;
}
.content{
    flex: 1;
    padding: 0.7rem;
    box-sizing: border-box;
    background: #F5F5F5;
}
</style>