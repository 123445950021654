import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import backIndex from '../views/backIndex.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: '登录',
        component: () => { return import ('../views/login.vue') },
    },
    {
        path: '/webback',
        name: '官网后台',
        component: backIndex,
        redirect: '/showManagement',
        children: [{
            path: '/showManagement',
            name: '展示管理',
            component: () => { return import ('../views/showManagement.vue') },
        }, {
            path: '/replyManagement',
            name: '回复管理',
            component: () => { return import ('../views/replyManagement.vue') }
        }, {
            path: '/addArticle',
            name: '添加文章',
            component: () => { return import ('../views/addArticle.vue') }
        }]
    },
    {
        path: '/',
        name: 'index',
        component: index,
        redirect: '/home',
        children: [{
                path: '/home',
                name: '首页',
                component: () => { return import ('../views/home.vue') },
            },
            {
                path: '/productService',
                name: '产品服务',
                component: () => { return import ('../views/productService.vue') },
            },
            {
                path: '/industryDynamic',
                name: '行业动态',
                meta: {
                    keepAlive: true
                },
                component: () => { return import ('../views/industryDynamic.vue') },
            },
            {
                path: '/caseShow',
                name: '案例展示',
                meta: {
                    keepAlive: true
                },
                component: () => { return import ('../views/caseShow.vue') },
            },
            {
                path: '/winWin',
                name: '合作共赢',
                component: () => { return import ('../views/winWin.vue') },
            },
            {
                path: '/detail',
                name: '详情',
                component: () => { return import ('../views/detail.vue') },
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path == "/login") {
        next();
    } else if (to.path == '/showManagement' || to.path == '/addArticle' || to.path == '/replyManagement') {
        const token = JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user")).token;
        if (token) {
            next();
        } else {
            localStorage.clear();
            router.push({
                path: "/login"
            });
        }
    } else {
        next()
    }
});

export default router