<template>
    <footer>
        <ul class="fot-ul1 ul1">
            <li><h3>关于我们</h3></li>
            <li @click="$router.push('/home')">公司介绍</li>
            <li @click="$router.push('/caseShow')">案例展示</li>
            <li @click="$router.push('/productService')">产品服务</li>
            <li @click="$router.push('/winWin')">合作共赢</li>
        </ul>
        <ul class="fot-ul1 ul2">
            <li><h3>联系我们</h3></li>
            <li>邮箱地址：yfhz@yfhuizhi.com</li>
            <li>联系电话：028-8521 4747</li>
            <li>服务热线：400-8521-747</li>
            <li>企业地址：四川省成都市高新区益州大道北段555号B座18楼</li>
            <li>备案编号：<a href="https://beian.miit.gov.cn/#/Integrated/index"  target="_blank" style="color:#fff">蜀ICP备20006618号-1</a> &nbsp;&nbsp;联网备案号：51019002003885</li>
        </ul>
        <div class="img-box">
            <img  v-lazy="require('../assets/images/公众号二维码.webp')" >
            <p>易方汇智公众号</p>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
footer{
    color: #fff;
    width: 100%;
    height: 238px;
    background: #4D97FF;
    display: flex;
    align-items: center;
    .fot-ul1{
        height: 80%;
        width: 100px;
        margin-left: 460px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .ul1{
        cursor: pointer;
    }
    .ul2{
        width: 500px;
        margin-left: 234px;
    }
    .img-box{
        text-align: center;
        p{
            font-size: 13px;
            margin-top: 10px;
        }
    }
}
</style>