<template>
  <div class="swiper-box">
    <div class="swiper-container">
      <div class="swiper-wrapper" @click="swiperClick">
        <div class="swiper-slide">
          <img src="../assets/images/banner1.webp"/>
        </div>
        <div class="swiper-slide">
          <img src="../assets/images/banner2.webp"/>
        </div>
        <div class="swiper-slide">
          <img src="../assets/images/banner3.webp"/>
        </div>
      </div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
    methods:{
        swiperClick(){

        },
    },
    mounted(){
        this.$nextTick(()=>{
            var mySwiper = new Swiper(".swiper-container", {
                speed: 1200, //滑动速度
                loop: true,
                spaceBetween: 0, //每个元素的间距
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false, //鼠标操作后不会停止
                },
                preventClicks : false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                observer: true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,//修改swiper的父元素时，自动初始化swiper
            });
            mySwiper.el.onmouseover = function () {
                mySwiper.autoplay.stop();
            };
            mySwiper.el.onmouseleave = function () {
                mySwiper.autoplay.start();
            };
        })
    }
}
</script>

<style lang="scss" scoped>
.swiper-box{
    height: 500px;
    width: 100%;
    margin: 0 auto;
    .swiper-container{
        width: 100%;
        height: 100%;
    }
    img{
        width: 100%;
        height: 100%;
    }
}
</style>