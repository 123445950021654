import axios from 'axios';
import { Message } from 'element-ui'
import router from '../router/index.js'
axios.defaults.baseURL = 'https://www.yfhuizhi.com/webservice';
//请求拦截器
axios.interceptors.request.use(function(config) {
    if (localStorage.getItem('user')) {
        config.data = {
            ...config.data,
            token: JSON.parse(localStorage.getItem('user')).token,
            userId: JSON.parse(localStorage.getItem('user')).userId,
            userName: JSON.parse(localStorage.getItem('user')).userName,
        }
    }
    return config;
}, function(error) {
    return Promise.reject(error);
});

//响应拦截器即异常处理
axios.interceptors.response.use(function(response) {
    if (response.data.code == 401) {
        Message.error('token失效！');
        router.push('/login')
    }
    return response;
}, function(err) {
    if (err && err.response) {
        if (err.response.status == 401) {
            Message.error('token失效！');
            router.push('/login')
        }
    } else {
        Message.error(`连接到服务器失败`);
    }
    return Promise.reject(err);
});

export default axios