import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/iconfont/iconfont.css';
import VueLazyload from 'vue-lazyload'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Head from './components/Head'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as $api from './api/index'
Vue.use(VueQuillEditor)

Vue.use(VueLazyload)
Vue.use(ElementUI)
Vue.component('Head', Head)
Vue.config.productionTip = false
Vue.prototype.$api = $api

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')