import axios from './request'

//登录
export const login = (data) => {
    return axios.post('/login', data)
}

//获取文章（全部信息）
export const getArticle = (data) => {
    return axios.post('/getArticle', data)
}

//获取文章主要内容
export const getArticleSummary = (data) => {
    return axios.post('/getArticleSummary', data)
}

//获取文章(后台列表)
export const getArticleBack = (data) => {
    return axios.post('/getArticleBack', data)
}


//获取文章(web文章详情)
export const getArticleContent = (data) => {
    return axios.post('/getArticleContent', data)
}

//添加文章
export const addArticle = (data) => {
    return axios.post('/addArticle', data)
}

//删除文章
export const deleteArticle = (data) => {
    return axios.post('/deleteArticle', data)
}

//新增问题
export const addQuestions = (data) => {
    return axios.post('/addQuestions', data)
}

//获取问题列表
export const getQuestions = (data) => {
    return axios.post('/getQuestions', data)
}

//删除问题
export const deleteQuestions = (data) => {
    return axios.post('/deleteQuestions', data)
}

//新增回复
export const addReply = (data) => {
    return axios.post('/addReply', data)
}

//获取回复
export const getReply = (data) => {
    return axios.post('/getReply', data)
}

//删除回复
export const deleteReply = (data) => {
    return axios.post('/deleteReply', data)
}

//获取问题和回复 树形数据
export const getQuestionsTree = (data) => {
    return axios.post('/getQuestionsTree', data)
}