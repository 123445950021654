<template>
  <div class="container">
    <Head></Head>
    <Swiper></Swiper>
    <keep-alive>
        <router-view v-if='$route.meta.keepAlive'></router-view>
    </keep-alive>
    <router-view v-if='!$route.meta.keepAlive'></router-view>
    <Footer></Footer>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Head from '../components/Head';
import Swiper from '../components/Swiper';
import Footer from '../components/Footer';
import ToTop from '../components/ToTop';
export default {
    name: 'home',
    components:{ Head,Swiper,Footer,ToTop },
    data(){
        return{

        }
    },
    methods:{
        
    },
    created(){
        document.body.style.height = "100%"
        document.documentElement.style.height = "100%"
    },
    mounted(){
        
    },
}
</script>

<style lang="scss" scoped>
.container{
    height: 100%;
    width: 100%;
    background: #E9EDF0;
}
</style>