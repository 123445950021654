<template>
  <div  @click="goAnchor('#head')" class="totop-box"></div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted(){
        var timer = null;
        window.onscroll = function(){
            if (timer) {
                clearTimeout(timer);
            } else {
                if(document.documentElement.scrollTop >= 600){
                    document.querySelector('.totop-box').style.opacity = 1
                }else{
                    document.querySelector('.totop-box').style.opacity = 0
                }
            }
            timer = setTimeout(() => {
                timer = null;
            }, 50)
        }
    },
    methods:{
         goAnchor(selector) {
            if(document.documentElement.scrollTop < 600) return
            document.querySelector(selector).scrollIntoView({
                behavior: "smooth"
            });
        },
    }
}
</script>

<style lang='scss' scoped>
.totop-box{
    width: 80px;
    height: 80px;
    position: fixed;
    bottom: 10%;
    right: 5%;
    box-shadow: 0 5px 14px 3px rgba(0,0,0,0.18);
    opacity:0;
    transition: all 0.5s;
    z-index: 10;
    cursor: pointer;
    background:#f4f4f4 url(http://www.100tal.com/images/icon-gotop.png) no-repeat center center;
}
@media screen and (max-width:800px){
    .totop-box{
        display: none;
    }
}
</style>