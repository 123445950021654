<template>
  <div class="head" id="head">
      <img class="logo" v-lazy="require('../assets/images/logo.webp')">
      <nav>
          <div @click="checkNav(1)"><p>公司首页</p></div>
          <div @click="checkNav(2)"><p>产品服务</p></div>
          <div @click="checkNav(3)"><p>行业动态</p></div>
          <div @click="checkNav(4)"><p>案例展示</p></div>
          <div @click="checkNav(5)"><p>合作共赢</p></div>
      </nav>
      <!-- <img src="" class="title" v-lazy="require('../assets/images/title.webp')"> -->
  </div>
</template>

<script>
export default {
    name: 'Head',
    data(){
        return{

        }
    },
    methods:{
        checkNav(index){
            const doc = document.querySelectorAll("nav div p");
            for(let i=0; i<doc.length; i++){
               doc[i].classList.remove("p1")
            }
            doc[index-1].classList.add("p1")
            switch(index){
                case 1:
                    this.$router.push("/home");
                break;
                case 2:
                    this.$router.push("/productService");
                break;
                case 3:
                    this.$router.push("/industryDynamic");
                break;
                case 4:
                    this.$router.push("/caseShow");
                break;
                case 5:
                    this.$router.push("/winWin");
                break;
            };
        }
    },
    mounted(){
        this.$nextTick(()=>{
            const doc = document.querySelectorAll("nav div p");
            for(let i=0; i<doc.length; i++){
               doc[i].classList.remove("p1")
            }
            switch(this.$route.path){
                case '/home':
                    doc[0].classList.add("p1")
                break;
                case '/productService':
                    doc[1].classList.add("p1")
                break;
                case '/industryDynamic':
                    doc[2].classList.add("p1")
                break;
                case '/caseShow':
                    doc[3].classList.add("p1")
                break;
                case '/winWin':
                    doc[4].classList.add("p1")
                break;
            };
            if(this.$route.query.type == 0){
                doc[2].classList.add("p1")
            }
            if(this.$route.query.type == 1){
                doc[3].classList.add("p1")
            }
        })
    },
    watch:{
        $route:function(){
            const doc = document.querySelectorAll("nav div p");
            for(let i=0; i<doc.length; i++){
               doc[i].classList.remove("p1")
            }
            switch(this.$route.path){
                case '/home':
                    doc[0].classList.add("p1")
                break;
                case '/productService':
                    doc[1].classList.add("p1")
                break;
                case '/industryDynamic':
                    doc[2].classList.add("p1")
                break;
                case '/caseShow':
                    doc[3].classList.add("p1")
                break;
                case '/winWin':
                    doc[4].classList.add("p1")
                break;
            };
            if(this.$route.query.type == 0){
                doc[2].classList.add("p1")
            }
            if(this.$route.query.type == 1){
                doc[3].classList.add("p1")
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.head{
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
}
.logo{
    width: 200px;
    height: 60px;
    margin-left: 19%;
}
nav{
    height: 100%;
    width: 45%;
    display: flex;
    margin-right: 17%;
    div{
        height: 100%;
        flex: 1;
        font-size: 18px;
        font-family: auto;
        font-weight: 600;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            height: 100%;
            width: 75%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.5s;
            white-space: nowrap;
        }
        .p1, p:hover{
            background: #4380E6;
            color: #fff;
        }
    }
}
.title{
    margin-right: 44%;
}
</style>