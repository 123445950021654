<template>
  <header>
        <div class="head-title">
            <img src="../assets/images/logo2.webp" alt="">
            易方汇智官方网站后台管理系统
        </div>
        <el-dropdown @command="hehe">
            <span class="el-dropdown-link">
                <i class="el-icon-s-custom el-icon--left" style="font-size:18px"></i>
                欢迎你！{{userName}}
                <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
                <el-dropdown-menu >
                    <el-dropdown-item command="webHome">官网首页</el-dropdown-item>
                    <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
  </header>
</template>

<script>
export default {
    name:'Head',
    data(){
        return{
            userName:'',
        }
    },
    methods:{
        hehe(command){
            if(command === "webHome"){
                this.$router.push('/')
            }
            if(command === "loginOut"){
                localStorage.clear();
                this.$router.push('/login')
            }
        }
    },
    mounted(){
        this.userName = JSON.parse(localStorage.getItem('user')).userName
    }
}
</script>

<style lang="scss">
header{
    background: #437EE6;
    height: 7%;
    padding: 0 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        width: 48px;
        height: 50px;
    }
    .head-title{
        width: 22%;
        min-width: 25.625rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-weight: bold;
        font-size: 21px;
        letter-spacing: 2px;
        white-space: nowrap;
    }
    .el-dropdown{
        color: #fff;
        font-size: 0.9375rem;
    }
}
</style>