<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss">
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html, body {
    width: 100%;
    min-height: 100%;
    height: auto;
    background: #E9EDF0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
ul,ol {
    list-style: none;
}
input,button{
    border: none;
    outline: none
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
    -webkit-appearance: none !important;
    margin: 0; 
}
body::-webkit-scrollbar,ul::-webkit-scrollbar {
    width: 7px;
    height: 6px;
}
body::-webkit-scrollbar-track,ul::-webkit-scrollbar-track {
    background-color: #fff;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}
body::-webkit-scrollbar-thumb,ul::-webkit-scrollbar-thumb {
    background-color: #ccc;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    width: 2px;
    border-radius: 2em;
    height: 2px;
}

.swiper-box{
    .swiper-container{
        --swiper-navigation-color: #fff;/* 单独设置按钮颜色 */
        --swiper-navigation-size: 30px;/* 设置按钮大小 */
        --swiper-pagination-color: #fff;/* 两种都可以 */
        .swiper-button-prev{
            margin-left: 30px;
        }
        .swiper-button-next{
            margin-right: 30px;
        }
        .swiper-pagination{
            margin-bottom: 10px;
        }
        .swiper-pagination-bullet{ background: #B3B3B3; opacity: 1;}
        .swiper-pagination-bullet-active{  background: #fff } 
    }
}
.ql-container{
    overflow-y:auto;
    height: 39rem !important;
    border: none !important;
}
/*滚动条整体样式*/
.ql-container ::-webkit-scrollbar{
  width: 8px;/*竖向滚动条的宽度*/
  height: 10px;/*横向滚动条的高度*/
}
.ql-container ::-webkit-scrollbar-thumb{/*滚动条里面的小方块*/
  background: #aaa;
  border-radius: 5px;
}
.ql-container ::-webkit-scrollbar-track{/*滚动条轨道的样式*/
  background: #eee;
  border-radius: 5px;
}
.ql-container .ql-editor{
    height: 100% !important;
    font-size: 15px;
    letter-spacing: 3px;
    border: 1px solid #ddd;
    border-top: none;
}
.quill-editor{
    padding: 0 !important;
}
.ql-snow{
    line-height: 35px !important;
    padding: 0 !important;
}
.ql-editor{
    img{
        max-width: 930px ;
        max-height: 620px ;
    }
}
</style>
